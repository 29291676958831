.is-hidden {
  display: none !important;
}

.aspectContainer {
  width: 100%;
  display: flex;
  overflow: hidden;

  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 100%;
  }

  &::after {
    /* to clear float */
    content: "";
    display: table;
    clear: both;
  }

  & video {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  & img {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  &.webcamPreviewContainer {
    display: flex;
    justify-content: center;
    position: relative;

    & #captureSourceImage {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.35;

      & img {
        width: 100%;
        height: 100%;
      }
    }

    &.userFacing video,
    &.userFacing #captureSourceImage img {
      transform: scaleX(-1);
    }

    & #countdownOverlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      & p {
        font-weight: 600;
        font-size: 5rem;
        color: white;
      }
    }
  }
}
