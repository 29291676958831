.adminPage {
  width: 100%;
  max-width: 800px;
  margin: 32px auto;

  form {
    & .formGroup {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      & label {
        display: inline-block;
        width: 20%;
        text-align: right;
        margin-right: 8px;
      }

      & input {
        flex: 1;
        font-size: 1.2rem;
      }

      & textarea {
        flex: 1;
        height: 200px;
        font-size: 1.2rem;
      }
    }

    button {
      padding: 12px 16px;
      margin-top: 16px;
    }
  }
}

.adminSequenceImages {
  display: flex;
  flex-flow: row wrap;
  margin-left: -8px;
  width: 100%;

  & li {
    list-style-type: none;
    width: 240px;
    margin: 0 8px 8px 0; /* Some gutter */

    & img {
      width: 240px;
      height: 240px;
    }

    & p {
      margin: 0;
    }
  }
}
