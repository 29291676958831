@import-normalize;

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}

#root {
  width: 100%;
  height: 100%;
}

strong {
  font-weight: 900;
}

em {
  font-style: italic;
}

a {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

#footerText {
  font-weight: 500;
  font-style: normal;
  text-align: center;
}

@import "./admin.scss";
@import "./home.scss";
@import "./sequence.scss";
@import "./webcam.scss";
