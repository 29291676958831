.sequencePage {
  width: 100%;
  height: 100%;
  padding: 25px 0;

  & .content {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    & #sequenceLogo {
      margin: 0;
      margin-bottom: 25px;
      display: flex;
      justify-content: center;
    }

    & #noImages {
      text-align: center;
      margin: 30px;
    }

    & #videoDisplay {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      position: relative;

      & #generatingOverlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;

        & p {
          font-weight: 600;
          font-size: 2rem;
          color: white;
        }
      }
    }

    & #sequenceButtons {
      margin: 25px 30px 0 30px;
      display: flex;
      justify-content: center;

      & a {
        display: block;
        margin: 0 25px;

        & img {
          width: 54px;
          height: 54px;
        }
      }
    }

    & .captureButtons {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 25px 30px 0 30px;

      & button {
        background: none;
        border: 0;
        outline: 0;
        margin: 0;
        padding: 0;

        & img {
          width: 100%;
        }

        &:active {
          opacity: 0.5;
        }
      }

      & .left {
        width: 62px;
      }

      & .center {
        width: 90px;
      }

      & .right {
        width: 62px;
      }
    }

    & #sequenceDescription {
      text-align: center;
      margin: 25px 30px;
    }
  }
}
